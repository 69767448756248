import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export default function CustomWrapper({meta, cmp}) {
    const Component = cmp

  return (
    <>
    <HelmetProvider>
        <Helmet>
          <title>{meta ? meta.title : "Title not set"}</title>
        </Helmet>
        {Component && <Component />}
      </HelmetProvider>
    </>
  )
}
