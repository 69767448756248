import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container py-5">
          <div className="row gy-5 gy-lg-0">
            <div className="col-lg-3 d-flex align-items-center">
              <div className="logo">
                <img src="images/logo-white.png" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-3">
              <h5>Quick Links</h5>
              <ul className="footer-menu">
                <li>
                  <NavLink to="#">Home</NavLink>
                </li>
                <li>
                  <NavLink to="#">About</NavLink>
                </li>
                <li>
                  <NavLink to="#">Products</NavLink>
                </li>
                <li>
                  <NavLink to="#">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="#">Privacy Policy</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h5>Socila Links</h5>
              <ul className="footer-menu">
                <li>
                  <NavLink to="#">Instagram</NavLink>
                </li>
                <li>
                  <NavLink to="#">Facebook</NavLink>
                </li>
                <li>
                  <NavLink to="#">Whatsapp</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <ul className="contact-info">
                <li className="align-items-start">
                  <i className="fa-solid fa-location-dot"></i>
                  <address className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Enim sunt, impedit unde amet quasi dolore.
                  </address>
                </li>
                <li>
                  <i className="fa-solid fa-phone"></i>
                  <a href="#">+91 1234567890</a>
                </li>
                <li>
                  <i className="fa-solid fa-envelope"></i>
                  <a href="#">info@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright py-4 text-center">
          <div className="container">
            <p className="mb-0">©2024 Potter. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
