import React, { useState } from "react";

export default function QtyPlusAndMinus() {
  const [qty, setQty] = useState(0);

  return (
    <>
      <div className="qty-plus-minus-btn">
        <button type="button" onClick={() => setQty(qty + 1)}>
          <i className="fa-solid fa-plus"></i>
        </button>
        <span>{qty}</span>
        <button type="button"  onClick={() => {
            if(qty > 0){
                setQty(qty - 1)
            }
        }}>
          <i className="fa-solid fa-minus"></i>
        </button>
      </div>
    </>
  );
}
