import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import CustomWrapper from "./wrapper/CustomWrapper";

export default function RouterRoot() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CustomWrapper
            cmp={Home}
            meta={{
              title: "Potter Pumps - Home",
            }}
          />
        }
      />
    </Routes>
  );
}
