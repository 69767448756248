import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import { BrowserRouter } from "react-router-dom";
import RouterRoot from "./RouterRoot";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";

export default function MainIndex() {
  return (
    <BrowserRouter>
      <Header />
      <RouterRoot />
      <Footer />
    </BrowserRouter>
  );
}
