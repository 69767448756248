import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { headerCartMenuToggle } from "../../redux-service/actions/actions";
import { constants } from "../../Constants";
import QtyPlusAndMinus from "../button/QtyPlusAndMinus";
import RemoveToCartBtn from "../button/RemoveToCartBtn";

export default function CartMenu() {
  const IsCartMenu = useSelector(
    (state) => state.headerReducer.cart_menu_toggle
  );
  const dispatch = useDispatch();

  function cartMenuOpenHandler() {
    dispatch(headerCartMenuToggle());
  }

  useEffect(() => {
    document.body.style.overflow = IsCartMenu ? "hidden" : "unset";
  }, [IsCartMenu]);

  return (
    <>
      <li
        className="cart-menu"
        onClick={cartMenuOpenHandler}
        style={{ cursor: "pointer" }}
      >
        <Badge badgeContent={4} color="primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="32"
            height="32"
            fill="currentColor"
            id="cart-icon"
            aria-haspopup="true"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
          </svg>
        </Badge>
        <div>
          <span>Your Cart</span>
          <b>0.00</b>
        </div>
      </li>
      <div
        className={`cart-menu-dropdown ${
          IsCartMenu ? "active" : ""
        } scrollbar-sm scrollbar-gray`}
      >
        <div className="cart-menu-head">
          <button type="button" className="btn btn-blue btn-icon">
            <i className="fa-solid fa-xmark"></i>
          </button>
          <h2 className="h3">Your Cart</h2>
        </div>
        <div className="cart-menu-body">
          <div className="table-responsive">
            <table className="table table-striped cart-menu-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>QTY</th>
                  <th>Sub Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="cart-menu-item">
                      <div className="cart-menu-item-img">
                        <img
                          src="images/potter-pump-product.jpg"
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="cart-menu-item-content">
                        <p>
                          <b>0.50 HP Openwell Pump</b>
                        </p>
                        <p>
                          <b>{constants.currency_symbol} 2650</b>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <QtyPlusAndMinus />
                  </td>
                  <td>{constants.currency_symbol} 2650</td>
                  <td>
                    <RemoveToCartBtn />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="cart-menu-item">
                      <div className="cart-menu-item-img">
                        <img
                          src="images/potter-pump-product.jpg"
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="cart-menu-item-content">
                        <p>
                          <b>0.50 HP Openwell Pump</b>
                        </p>
                        <p>
                          <b>{constants.currency_symbol} 2650</b>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <QtyPlusAndMinus />
                  </td>
                  <td>{constants.currency_symbol} 2650</td>
                  <td>
                    <RemoveToCartBtn />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="cart-menu-item">
                      <div className="cart-menu-item-img">
                        <img
                          src="images/potter-pump-product.jpg"
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="cart-menu-item-content">
                        <p>
                          <b>0.50 HP Openwell Pump</b>
                        </p>
                        <p>
                          <b>{constants.currency_symbol} 2650</b>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <QtyPlusAndMinus />
                  </td>
                  <td>{constants.currency_symbol} 2650</td>
                  <td>
                    <RemoveToCartBtn />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="cart-menu-item">
                      <div className="cart-menu-item-img">
                        <img
                          src="images/potter-pump-product.jpg"
                          className="img-fluid"
                          alt="..."
                        />
                      </div>
                      <div className="cart-menu-item-content">
                        <p>
                          <b>0.50 HP Openwell Pump</b>
                        </p>
                        <p>
                          <b>{constants.currency_symbol} 2650</b>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <QtyPlusAndMinus />
                  </td>
                  <td>{constants.currency_symbol} 2650</td>
                  <td>
                    <RemoveToCartBtn />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={2}>Total</th>
                  <th colSpan={2}>{constants.currency_symbol} 2650</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="cart-menu-foot">
          <button type="button" className="btn btn-black">
            Proceed To Buy
          </button>
        </div>
      </div>
      <div
        className={`cart-menu-dropdown-overlay ${IsCartMenu ? "active" : ""}`}
        onClick={cartMenuOpenHandler}
      ></div>
    </>
  );
}
