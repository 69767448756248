import React from "react";

export default function RemoveToCartBtn() {
  return (
    <>
      <button type="button" className="btn btn-red btn-icon btn-sm">
        <i className="fa-solid fa-xmark"></i>
      </button>
    </>
  );
}
