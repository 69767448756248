import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { NavLink } from "react-router-dom";
import { constants } from "../Constants";
import AddToCartBtn from "../component/button/AddToCartBtn";

export default function Home() {
  return (
    <>
      <section className="hero-banner">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, A11y]}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center fade-left">
                  <img
                    src="images/banner-img-1.png"
                    alt=""
                    className="img-fluid hero-banner-img"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3">Best Quality</span>
                    <h2 className="heading-1 mb-3">
                      Durable Openwell <span>Pump</span> Solutions
                    </h2>
                    <p className="large-text-1 mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <a href="#" className="btn btn-black mt-2">
                      <span>Shop Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container">
              <div className="row gy-4 gx-lg-5 justify-content-between">
                <div className="col-lg-6 d-flex justify-content-center align-items-center fade-left">
                  <img
                    src="images/banner-img-1.png"
                    alt=""
                    className="img-fluid hero-banner-img"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hero-banner-content">
                    <span className="small-heading mb-3">Best Quality</span>
                    <h2 className="heading-1 mb-3">
                      Durable Openwell <span>Pump</span> Solutions
                    </h2>
                    <p className="large-text-1 mb-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                    <a href="#" className="btn btn-black mt-2">
                      <span>Shop Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xxl-9">
              <span className="small-heading">About Company</span>
              <h1 className="mb-2 mt-1">
                Setting a tradition of excellence since 1981.
              </h1>
              <p className="large-text-2 mb-4">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem culpa accusamus, velit, quos praesentium sed in cupiditate odit explicabo provident possimus distinctio, iusto ea sapiente earum. Vero fuga iure alias totam placeat non sequi dolorum!
              </p>
              <NavLink to="#" className="btn btn-black">
                About Us
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <span className="small-heading">Our Products</span>
              <h2 className="heading-2 mb-0 mt-1">Our Products</h2>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-end align-items-center">
              <NavLink to="#" className="btn btn-black">
                View All
              </NavLink>
            </div>
            <div className="col-12 mt-5">
              <div className="product-card-grid">
                <div className="grid-item">
                  <div className="card product-card-1 card-dark">
                    <div className="card-img">
                      <img
                        src="images/potter-pump-product.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <div className="product-card-text">
                        <h5 className="card-title">0.50 HP Openwell Pump</h5>
                        <p className="card-price mb-0">
                          <span
                            style={{
                              color: "var(--light-text-color)",
                              fontWeight: "var(--fw-medium)",
                            }}
                          >
                            Price:
                          </span>{" "}
                          {constants.currency_symbol} 2650/- + GST 18%
                        </p>
                      </div>
                      <AddToCartBtn />
                    </div>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="card product-card-1">
                    <div className="card-img">
                      <img
                        src="images/potter-pump-product.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <div className="product-card-text">
                        <h5 className="card-title">1.00 HP Openwell Pump</h5>
                        <p className="card-price mb-0">
                          <span
                            style={{
                              color: "var(--light-text-color)",
                              fontWeight: "var(--fw-medium)",
                            }}
                          >
                            Price:
                          </span>{" "}
                          {constants.currency_symbol} 3650/- + GST 18%
                        </p>
                      </div>
                      <AddToCartBtn />
                    </div>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="card product-card-1">
                    <div className="card-img">
                      <img
                        src="images/potter-pump-product.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <div className="product-card-text">
                        <h5 className="card-title">
                          0.50 HP SELF Priming Pump
                        </h5>
                        <p className="card-price mb-0">
                          <span
                            style={{
                              color: "var(--light-text-color)",
                              fontWeight: "var(--fw-medium)",
                            }}
                          >
                            Price:
                          </span>{" "}
                          {constants.currency_symbol} 2550/- + GST 18%
                        </p>
                      </div>
                      <AddToCartBtn />
                    </div>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="card product-card-1">
                    <div className="card-img">
                      <img
                        src="images/potter-pump-product.jpg"
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                    <div className="card-body">
                      <div className="product-card-text">
                        <h5 className="card-title">
                          0.75 HP SELE PRIMing PUMP
                        </h5>
                        <p className="card-price mb-0">
                          <span
                            style={{
                              color: "var(--light-text-color)",
                              fontWeight: "var(--fw-medium)",
                            }}
                          >
                            Price:
                          </span>{" "}
                          {constants.currency_symbol} 3450/- + GST 18%
                        </p>
                      </div>
                      <AddToCartBtn />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center d-lg-none mt-4 pt-2">
              <NavLink to="#" className="btn btn-black">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">ISO 9001:2015</h2>
              <p className="large-text-2 mb-0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                aliquid cumque, vero ex quidem ab natus. Placeat, quaerat! Totam
                esse est a fugit maxime repudiandae accusamus dolores
                reprehenderit porro ipsam!
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-end mb-4 mb-lg-0">
              <img
                src="images/iso-9001-2015-logo.png"
                alt=""
                className="img-fluid"
                width="220"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-lg-row-reverse">
            <div className="col-lg-9 col-xxl-8">
              <h2 className="mb-2 mt-1">Best Quality</h2>
              <p className="large-text-2 mb-0">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Consequatur quo error a facere reiciendis. Corporis cum
                obcaecati voluptate saepe totam voluptatibus at eveniet veniam
                cupiditate.
              </p>
            </div>
            <div className="col-lg-3 col-xxl-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
              <img
                src="images/best-quality-logo.png"
                alt=""
                className="img-fluid"
                width="220"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
