import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import CartMenu from "./CartMenu";

export default function Header() {
  const location = useLocation();
  let [activeMenu, setActiveMenu] = useState(false);
  let [activeHeader, setActiveHeader] = useState(false);

  const pathname = location.pathname;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let activeClass = true;
      if (window.scrollY === 0) {
        activeClass = false;
      }
      setActiveHeader(activeClass);
    });
  }, [pathname]);

  useEffect(() => {
    document.body.style.overflow = activeMenu ? "hidden" : "unset";
  }, [activeMenu]);

  return (
    <>
      <div
        className={`overlay ${activeMenu ? "active-menu" : ""}`}
        onClick={() => setActiveMenu(!activeMenu)}
      ></div>
      <header
        className={`main-header ${activeHeader ? "active" : ""} ${
          activeMenu ? "active-menu" : ""
        }`}
      >
        <div className="container">
          <div className="header-top-belt">
            <div className="logo">
              <img src="images/logo.png" alt="" className="img-fluid" />
            </div>
            <div className="header-top-belt-center">
              {!activeHeader && (
                <div className="header-search-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for products"
                  />
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
              )}
              <ul className="menu">
                <li>
                  <NavLink to="">Home</NavLink>
                </li>
                <li>
                  <NavLink to="">About</NavLink>
                </li>
                <li>
                  <NavLink to="">Products</NavLink>
                </li>
                <li>
                  <NavLink to="">Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="header-top-belt-right">
              <ul>
                <ProfileMenu />
                <CartMenu />
              </ul>
              <div
                className="menubar"
                onClick={() => setActiveMenu(!activeMenu)}
              >
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
