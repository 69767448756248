import { HEADER_CART_MENU_TOGGLE } from "../constants";

const headerInitialState = {
  cart_menu_toggle: false,
};

export const headerReducer = (state = headerInitialState, action) => {
  switch (action.type) {
    case HEADER_CART_MENU_TOGGLE:
      return {
        ...state,
        cart_menu_toggle: !state.cart_menu_toggle,
      };

    default:
      return state;
  }
};
